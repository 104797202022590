<template>
  <div className="animated fadeIn">

      <v-server-table ref="table" :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <!--<template slot="status" slot-scope="data">
            <b-badge :variant="getStatus(data.status)">{{data.status}}</b-badge>
          </template> -->

          <template slot="__checkbox" slot-scope="props">
              <input type='checkbox' v-model="checkedData" :value="props.row.id">
          </template>

          <template slot="actions" slot-scope="props">
            <b-button variant="secondary" :to="{ name: 'Task', params: { id: props.row.id } }">View</b-button>
          </template>

      </v-server-table>

      <BulkAssignModal></BulkAssignModal>

      <b-button v-if="checkedData.length > 0" v-b-modal="'task-bulk-assign'">Bulk Assign Tasks</b-button>
      
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'
  import BulkAssignModal from './bulk-assign.vue'

  Vue.use(ServerTable)


  let url = '/tasks/';

  export default {
    name: 'DataTable',
    components: {
      ServerTable,
      BulkAssignModal
    },
    data: function () {
      return {
        columns: [
          /*'id',*/'__checkbox', 'type', 'description', 'assigned_to_pretty', 'status_pretty', 'created_at',  'due_date', 'actions'],
        data: [],
        users: [],
        checkedData: [],
        fields: [
          {
          name: '__checkbox',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        },
        ],
        options: {
          requestFunction: (data) => {
              return axios.get(url, {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('quotes error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {            
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
            assigned_to_pretty: 'Assigned To',
            status_pretty: 'Status',
            created_at: 'Created',
            __checkbox: '',
          },
          sortable: [],
          filterable: [],//['status',],
          filterByColumn: true,
          listColumns: {
            status: [
              {
                id: 1,
                text: 'To be assigned'
              },
              {
                id: 2,
                text: 'Assigned'
              },
              {
                id: 3,
                text: 'In progress'
              },
              {
                id: 4,
                text: 'Complete'
              }
            ]
          },
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
    },
    computed: {
      user_permissions() {
        return this.$store.getters.getUserPermissions
      },
      hasAccess() {

        let access = ['full_admin', 'customer_services'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
    }
  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
