<template>
  <div>
    <b-modal
      id="task-bulk-assign"
      ref="modal"
      title="Bulk Assign Tasks"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">


        <b-form-group
          label="User"
          label-for="users"
          invalid-feedback="User is required"
          :state="validateState('user')"
        >
          <b-form-select id="users" v-model="$v.user.$model" :options="users" :state="validateState('user')"></b-form-select>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>

import axios from "../../../shared/axios/auth-no-loader";
import { validationMixin } from 'vuelidate'
import {required} from "vuelidate/lib/validators";


export default {
  props: {
  },
  data() {
    return {
      error: false,
      user: "",
      users: []
    };
  },
  mixin: [validationMixin],
  validations() {
      return {
        user: {required},
      }
  },
  methods: {
    validateState(name) {

      if (this.$v[name]) {
        const {$dirty, $error} = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    resetModal() {
      this.user = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      this.$v.$touch();

      if(this.$v.$anyError) {

        this.error = true;

        return

      } else {

        let data = {
          user: this.user,
          tasks: this.$parent.checkedData
        };
    
        axios
          .put("/tasks/bulk/", data)
          .then(response => {
            response;

            this.user = "";
            this.$v.$reset();

            this.$parent.checkedData = []
            console.log(this.$parent.$refs)
            this.$parent.$refs.table.refresh();

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("task-bulk-assign");
            });

          })
          .catch(error => {

            console.log(error)

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch("logout");
            }
          });
        }

      }
  },
  mounted() {
     axios
          .get("/tasks/users/")
          .then(response => {
            this.users = response.data.users
      })
  }
};
</script>
